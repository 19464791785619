<template>
    <v-select 
        :value="value"
        @input="handleInput"
        :items="items"
        v-bind="$attrs"
        :label="$capitalize($tc('model.insureds_type_of_insured'))" 
        item-value="id"
        item-text="description"
    >
        <!-- Pass on all named slots -->
        <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />

        <!-- Pass on all scoped slots -->
        <template
            v-for="slot in Object.keys($scopedSlots)"
            :slot="slot"
            slot-scope="scope"
        >
            <slot :name="slot" v-bind="scope" />
        </template>
    </v-select>
</template>

<script>
export default {
    name: 'participants-type-picker',

    model: {
        prop: 'value',
        event: 'change'
    },

    props: {
        value: {
            type: Number
        }
    },

    data() {
        return {
            items: [],
            loading: false
        }
    },

    methods: {
        handleInput(value) {
            this.$emit('change', value);
        },
        async getItems() {
            this.loading = true;
            try {
                const response = await this.$http.get('participants/meta/types_picker');

                this.items = response.data;
            } catch(error) {
                this.$store.commit('error', "Falha ao carregar tipos de participantes");
                console.error(error);
            } finally {
                this.loading = false;
            }
        }
    },

    created() {
        this.getItems();
    }
}
</script>