import Vue from "vue";

export async function update(lead_number, payload) {
  const response = await Vue.prototype.$http.put(`/leads/${lead_number}/visit_report`, payload);

  return response.data;
}

export async function updateAttachment(id, attachment, payload) {
  const response = await Vue.prototype.$http.put(`/visit_reports/${id}/attachments/${attachment.id}`, payload);

  return response.data;
}