<style lang="scss">
.title {
  font-size: 25px;
}
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
</style>

<template>
  <v-app>
    <div v-if="state === 'loading'" class="loading">
      <div class="text-h5 mr-5">A carregar</div>
      <v-progress-circular
        color="primary"
        indeterminate
        size="50"
      ></v-progress-circular>
    </div>
    <div v-else-if="state === 'not_found'" class="loading">
      <div class="text-h4">
        <v-icon color="orange" size="45">mdi-help-circle-outline</v-icon>
        Tarefa não encontrada
      </div>
    </div>
    <div v-else-if="state === 'error'" class="loading">
      <div class="text-h4">
        <v-icon color="red" size="45">mdi-close-circle-outline</v-icon>
        Erro
      </div>
    </div>
    <div v-else-if="state === 'finished'" class="loading">
      <div class="text-h4">
        <v-icon color="green" size="45">mdi-check-circle-outline</v-icon>
        Tarefa Entregue
      </div>
    </div>
    <div v-else>
      <v-card elevation="12">
        <v-navigation-drawer v-model="drawer" app floating>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-center title">
                <h1>
                  <v-img src="@/assets/logo.png" width="170px"></v-img>
                </h1>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list dense nav>
            <v-list-item-group v-model="page" color="primary">
              <v-list-item :value="1">
                <v-list-item-icon>
                  <v-icon>mdi-home</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> Geral </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :value="2">
                <v-list-item-icon>
                  <v-icon>mdi-magnify</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> Avaliação </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item :value="3">
                <v-list-item-icon>
                  <v-icon>mdi-account-multiple</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> Participantes </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <span style="font-size: 13px">
                    {{ form.participants.length }}
                  </span>
                </v-list-item-action>
              </v-list-item>
              <v-list-item :value="4">
                <v-list-item-icon>
                  <v-icon>mdi-camera</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> Fotografias </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <span style="font-size: 13px">
                    {{ images.length }}
                  </span>
                </v-list-item-action>
              </v-list-item>
              <v-list-item :value="5">
                <v-list-item-icon>
                  <v-icon>mdi-paperclip</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> Anexos </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <span style="font-size: 13px">
                    {{ attachments.length }}
                  </span>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <template v-slot:append>
            <v-list>
              <v-list-item>
                <v-btn
                  @click="handleFinish()"
                  large
                  block
                  color="primary"
                  dark
                  outlined
                  :loading="loading_finish"
                >
                  <v-icon size="20" left>mdi-send</v-icon> Enviar
                </v-btn>
              </v-list-item>
            </v-list>
          </template>
        </v-navigation-drawer>
      </v-card>

      <v-main style="background-color: #f5f5f5; min-height: 100vh">
        <v-container>
          <v-alert
            v-if="getDeadLineInMinutes() > 0"
            :type="getAlertType()"
            text
          >
            <b>{{ getDeadline() }}</b> restantes para entregar.
          </v-alert>
          <v-alert v-else type="error" text>
            <b>{{ getDeadline() }}</b> em atraso
          </v-alert>
          <v-alert text type="error" v-if="task.task_disapproval_motive">
            <b>Motivo da Recusa:</b> {{ task.task_disapproval_motive }}
          </v-alert>

          <v-row v-if="fails.length > 0">
            <v-col>
              <v-card outlined color="red darken-3" dark>
                <v-card-title>
                  Envio da task recusado
                  <v-spacer></v-spacer>
                  <v-btn icon @click="fails = []">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-list dense color="red darken-3">
                  <v-list-item v-for="fail of fails" :key="fail">
                    <v-icon left color="red lighten-3">
                      mdi-backspace-reverse-outline
                    </v-icon>
                    {{ fail }}
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>

          <v-app-bar app elevation="0">
            <v-btn @click="drawer = !drawer" icon>
              <v-icon> mdi-menu </v-icon>
            </v-btn>
            <h1 v-if="!drawer">
              <v-img src="@/assets/logo.png" width="170px"></v-img>
            </h1>
            <v-spacer></v-spacer>
          </v-app-bar>

          <div class="page" v-if="page == 1">
            <div class="mb-10">
              <div class="text-h5">Geral</div>
              <v-divider class="mt-5"></v-divider>
            </div>

            <v-row>
              <v-col cols="12" md="6">
                <v-card>
                  <v-card-title>
                    <v-icon left>mdi-calendar</v-icon> Agendamento
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <b>Data</b>
                        <br />
                        {{ formatDateTime(header.scheduling.date) }}
                      </v-col>
                      <v-col cols="12" sm="6">
                        <b>Tipo</b>
                        <br />
                        {{ header.scheduling.type }}
                      </v-col>
                      <v-col cols="12">
                        <b>Observações</b>
                        <br />
                        {{
                          header.scheduling.observation || "Nenhuma observação"
                        }}
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col>
                <v-card>
                  <v-card-title>
                    <v-icon left>mdi-map-marker</v-icon> Local de Risco
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="4">
                        <b>Código Postal</b>
                        <br />
                        {{ header.risk_location.postal_code }}
                      </v-col>
                      <v-col cols="12" sm="4">
                        <b>Distrito</b>
                        <br />
                        {{ header.risk_location.country_area }}
                      </v-col>
                      <v-col cols="12" sm="4">
                        <b>Concelho</b>
                        <br />
                        {{ header.risk_location.city }}
                      </v-col>
                      <v-col cols="12" sm="12">
                        <b>Morada</b>
                        <br />
                        {{ header.risk_location.street }}
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-card>
                  <v-card-title>
                    <v-icon left> mdi-briefcase </v-icon> Sinistro
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <b>Código interno</b>
                        <br />
                        {{ header.lead.identifier_code }}
                      </v-col>
                      <v-col cols="12" sm="6">
                        <b>Código companhia</b>
                        <br />
                        {{ header.lead.external_reference }}
                      </v-col>
                      <v-col cols="12" sm="6">
                        <b>Data ocorrência</b>
                        <br />
                        {{ formatDate(header.sinister.claim_date) }}
                      </v-col>
                      <v-col cols="12" sm="6">
                        <b>Data Participação</b>
                        <br />
                        {{ formatDate(header.sinister.claim_involvement_date) }}
                      </v-col>
                      <v-col cols="12">
                        <b>Tipo</b>
                        <br />
                        {{ header.sinister.lead_type }}
                      </v-col>
                      <v-col cols="12">
                        <b>Descrição do segurado</b>
                        <br />
                        {{ header.sinister.description }}
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-card>
                  <v-card-title>
                    <v-icon left>mdi-account-check</v-icon> Segurado
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <b>Nome</b>
                        <br />
                        {{ header.insured.name }}
                      </v-col>
                      <v-col cols="12">
                        <b>Email</b>
                        <br />
                        {{ header.insured.email || "Não informado" }}
                      </v-col>
                      <v-col cols="6">
                        <b>Contato</b>
                        <br />
                        {{ header.insured.contact }}
                      </v-col>
                      <v-col cols="6">
                        <b>NIF</b>
                        <br />
                        {{ header.insured.fiscal_number }}
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-card>
                  <v-card-title>
                    <v-icon left>mdi-view-list</v-icon> Apólice
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <b>Número</b>
                        <br />
                        {{ header.policy.number }}
                      </v-col>
                      <v-col cols="6">
                        <b>Data Início</b>
                        <br />
                        {{ formatDate(header.policy.begin_date) }}
                      </v-col>
                      <v-col cols="6">
                        <b>Data Fim</b>
                        <br />
                        {{
                          formatDate(header.policy.end_date) || "Indeterminada"
                        }}
                      </v-col>
                    </v-row>

                    <v-divider class="my-5"></v-divider>

                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="policy_coverages_filter"
                          label="Procurar"
                          prepend-inner-icon="mdi-magnify"
                          hide-details
                          single-line
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-subheader>COBERTURAS</v-subheader>
                  <v-list>
                    <div
                      v-for="policy_coverage in filtered_policy_coverages"
                      :key="policy_coverage.id"
                    >
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ policy_coverage.type_description }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            Capital:
                            {{ formatMoney(policy_coverage.capital) }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            Franquia:
                            {{ formatMoney(policy_coverage.franchise_value) }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                    </div>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </div>
          <div class="page" v-else-if="page == 2">
            <ValidationObserver ref="form">
              <v-form>
                <v-row>
                  <v-col>
                    <v-card>
                      <v-card-title>
                        <v-icon left>mdi-ray-start-arrow</v-icon> Origem/Causa
                      </v-card-title>
                      <v-card-text>
                        <v-autocomplete
                          outlined
                          label="Origem/Causa"
                          v-model="form.origin_type_id"
                          @change="updateAttribute('origin_type_id', $event)"
                          item-value="id"
                          item-text="description"
                          :items="header.lead.available_origin_types"
                          clearable
                        />
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-card>
                      <v-card-title>
                        <v-icon left>mdi-file-chart</v-icon> Relatório
                      </v-card-title>
                      <v-card-text>
                        <v-textarea
                          outlined
                          label="Fatos Apurados"
                          :rows="10"
                          auto-grow
                          v-model="form.established_facts"
                          @change="updateAttribute('established_facts', $event)"
                        ></v-textarea>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-card>
                      <v-card-title>
                        <v-icon left> mdi-check-box-outline </v-icon>
                        Enquadramento
                      </v-card-title>
                      <v-card-text>
                        <v-radio-group
                          @change="updateAttribute('is_matched', $event)"
                          row
                          v-model="form.is_matched"
                        >
                          <v-radio label="Enquadrado" :value="true"></v-radio>
                          <v-radio
                            label="Não enquadrado"
                            :value="false"
                          ></v-radio>
                        </v-radio-group>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-card>
                      <v-card-title>
                        <v-icon left> mdi-magnify </v-icon> Pesquisa
                      </v-card-title>
                      <v-card-text>
                        <v-select
                          label="Necessita pesquisa?"
                          :items="[
                            {
                              value: true,
                              text: 'Sim',
                            },
                            {
                              value: false,
                              text: 'Não',
                            },
                          ]"
                          @change="updateAttribute('needs_research', $event)"
                          v-model="form.needs_research"
                        ></v-select>

                        <Timestamp
                          v-model="form.research_date"
                          :date.sync="research_date"
                          :time.sync="research_time"
                        >
                          <v-row>
                            <v-col>
                              <v-text-field
                                v-model="research_date"
                                type="date"
                                label="Data da Pesquisa"
                              ></v-text-field>
                            </v-col>
                            <v-col>
                              <v-text-field
                                v-model="research_time"
                                type="time"
                                label="Hora"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </Timestamp>

                        <MoneyInput
                          v-model="form.research_extimated_price"
                          label="Valor estimado"
                          @change="
                            updateAttribute(
                              'research_extimated_price',
                              machineFormat($event)
                            )
                          "
                        />
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-form>
            </ValidationObserver>
            <v-btn
              @click="updateAllForm()"
              fab
              color="primary"
              fixed
              right
              bottom
              :loading="submitting_form"
            >
              <v-icon> mdi-content-save </v-icon>
            </v-btn>
          </div>
          <div class="page" v-else-if="page == 3">
            <div class="mb-10">
              <div class="text-h5">Participantes</div>
              <v-divider class="mt-5"></v-divider>
            </div>
            <v-btn
              @click="handleNewParticipant"
              fab
              fixed
              bottom
              right
              color="primary"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-row>
              <v-col
                v-for="participant in form.participants"
                :key="participant.id"
                cols="12"
                sm="6"
                md="4"
                xl="3"
              >
                <v-card>
                  <v-card-title>
                    {{ abreviate(participant.name) }}
                    <v-spacer></v-spacer>
                    <v-btn @click="handleUpdateParticipant(participant)" icon>
                      <v-icon> mdi-square-edit-outline </v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-subtitle>
                    {{ participant.type.description }}
                  </v-card-subtitle>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <b>Email</b>
                        <br />
                        {{ participant.email || "Nenhum" }}
                      </v-col>
                      <v-col cols="12" sm="6">
                        <b>Contato</b>
                        <br />
                        {{ participant.contact || "Nenhum" }}
                      </v-col>
                      <v-col cols="12" sm="6">
                        <b>NIF</b>
                        <br />
                        {{ participant.fiscal_number }}
                      </v-col>
                      <v-col cols="12" sm="6">
                        <b>Lesado</b>
                        <br />
                        {{ participant.insured ? "Sim" : "Não" }}
                      </v-col>
                      <v-col cols="12">
                        <b>Objetivo</b>
                        <br />
                        {{
                          getParticipantDesire(participant.desire) ||
                          "Não informado"
                        }}
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row> </v-row>
          </div>
          <div class="page" v-else-if="page == 4">
            <v-row>
              <v-col>
                <v-card>
                  <v-card-title>
                    Fotografias
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="photo_dialog = true"
                      fab
                      color="primary"
                      fixed
                      bottom
                      right
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col
                        cols="12"
                        sm="6"
                        md="3"
                        xl="2"
                        v-for="image in images"
                        :key="image.id"
                      >
                        <v-img
                          @click="showImage(image)"
                          :src="image.public_url"
                          class="white--text align-end"
                          gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                          height="200px"
                          style="cursor: pointer"
                        >
                          <v-card-title v-text="image.name"></v-card-title>
                        </v-img>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
          <div class="page" v-else-if="page == 5">
            <div class="mb-10">
              <div class="text-h5">Anexos</div>
              <v-divider class="mt-5"></v-divider>
            </div>

            <v-btn
              @click="handleOpenNewFileDialog()"
              fab
              color="primary"
              fixed
              right
              bottom
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>

            <v-row>
              <v-col
                cols="12"
                sm="6"
                lg="4"
                xl="3"
                v-for="attachment in attachments"
                :key="attachment.id"
              >
                <v-card @click="showFile(attachment)" elevation="4">
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon
                        large
                        :color="getFilesIconColor(getFilesIcon(attachment))"
                      >
                        {{ getFilesIcon(attachment) }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ attachment.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle :title="attachment.description">
                        {{ attachment.description }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action class="grey--text">
                      .{{ attachment.extension.toLowerCase() }}
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-container>
        <v-dialog
          v-model="attachment_dialog"
          :value="true"
          max-width="650"
          persistent
        >
          <ValidationObserver ref="attachment_form">
            <v-form @submit.prevent="handleSubmitAttachment">
              <v-card>
                <v-card-title>
                  Upload
                  <v-spacer></v-spacer>
                  <v-btn @click="attachment_dialog = false" icon>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <ValidationProvider
                    name="file"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-file-input
                      name="file"
                      label="Anexo"
                      v-model="attachment_form.file"
                      @change="setAttachment($event)"
                      :disabled="attachment_loading"
                      :error-messages="errors"
                    ></v-file-input>
                  </ValidationProvider>
                  <ValidationProvider
                    name="name"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="Nome do arquivo"
                      name="name"
                      prepend-icon="mdi-file"
                      v-model="attachment_form.name"
                      :disabled="attachment_loading"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider
                    name="name"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-textarea
                      label="Descrição do arquivo"
                      v-model="attachment_form.description"
                      prepend-icon="mdi-text"
                      auto-grow
                      :disabled="attachment_loading"
                      :error-messages="errors"
                    ></v-textarea>
                  </ValidationProvider>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="attachment_dialog = false"
                    text
                    color="primary"
                    :disabled="attachment_loading"
                  >
                    CANCELAR
                  </v-btn>
                  <v-btn
                    :loading="attachment_loading"
                    color="primary"
                    type="submit"
                    >Enviar</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-form>
          </ValidationObserver>
        </v-dialog>

        <v-dialog v-model="photo_dialog" max-width="650" persistent>
          <v-card>
            <v-card-title>
              Fotografia
              <v-spacer></v-spacer>
              <v-btn
                @click="photo_dialog = false"
                icon
                :disabled="form_image_submitting"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-file-input
                accept="image/png, image/jpeg, image/bmp"
                chips
                multiple
                truncate-length="15"
                label="Fotografias"
                prepend-icon="mdi-camera"
                v-model="form_image.photos"
                :disabled="form_image_submitting"
              ></v-file-input>
              <v-combobox
                label="Divisão"
                :items="room_types.map((room_type) => room_type.name)"
                v-model="form_image.description"
                :disabled="form_image_submitting"
              ></v-combobox>
              <v-progress-linear
                :value="total_uploaded"
                height="20"
                label="Enviando arquivos"
                rounded
                :active="form_image_submitting"
              >
                <template v-slot="{ value }">
                  <strong :class="{ 'white--text': value > 52 }"
                    >{{ Math.floor(value) }} %</strong
                  >
                </template>
              </v-progress-linear>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="uploadFiles()"
                color="primary"
                :loading="form_image_submitting"
              >
                <v-icon left>mdi-upload-multiple</v-icon> Enviar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog max-width="650" v-model="participant_dialog" persistent>
          <ValidationObserver ref="participant_form">
            <v-form @submit.prevent="addParticipant()">
              <v-card outlined>
                <v-card-title>
                  Participante
                  <v-spacer></v-spacer>
                  <v-btn @click="participant_dialog = false" icon>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-subtitle v-if="new_participant.type_id == 1">
                  <v-chip small label color="primary">{{
                    new_participant.type.description
                  }}</v-chip>
                </v-card-subtitle>

                <v-card-text>
                  <ValidationProvider
                    name="name"
                    rules="required|max:100"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="Nome"
                      name="name"
                      v-model="new_participant.name"
                      :error-messages="errors"
                      :disabled="new_participant_loading"
                      :readonly="new_participant.type_id == 1"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider
                    name="type_id"
                    rules="required"
                    v-slot="{ errors }"
                    v-if="new_participant.type_id !== 1"
                  >
                    <participants-type-picker
                      label="Tipo"
                      name="type"
                      v-model="new_participant.type_id"
                      :error-messages="errors"
                      :disabled="new_participant_loading"
                    ></participants-type-picker>
                  </ValidationProvider>
                  <ValidationProvider
                    name="email"
                    rules="required|email|max:100"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="Email"
                      name="email"
                      type="email"
                      v-model="new_participant.email"
                      :error-messages="errors"
                      :disabled="new_participant_loading"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider
                    name="contact"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="Contato"
                      v-model="new_participant.contact"
                      :error-messages="errors"
                      :disabled="new_participant_loading"
                      name="contact"
                    ></v-text-field>
                  </ValidationProvider>
                  <ValidationProvider
                    name="fiscal_number"
                    :rules="{
                      length: 9,
                    }"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      label="NIF"
                      name="fiscal_number"
                      v-model="new_participant.fiscal_number"
                      :error-messages="errors"
                      :disabled="new_participant_loading"
                    ></v-text-field>
                  </ValidationProvider>
                  <v-switch
                    label="Participante é lesado"
                    v-model="new_participant.insured"
                    :disabled="new_participant_loading"
                    :readonly="new_participant.type_id == 1"
                  ></v-switch>
                  <ValidationProvider
                    name="desire"
                    rules=""
                    v-slot="{ errors }"
                  >
                    <v-select
                      label="Objetivo"
                      name="desire"
                      :items="[
                        {
                          text: 'Reparação',
                          value: 'rep',
                        },
                        {
                          text: 'Substituição',
                          value: 'sub',
                        },
                        {
                          text: 'Indemnização',
                          value: 'ind',
                        },
                      ]"
                      v-model="new_participant.desire"
                      :error-messages="errors"
                      clearable
                    ></v-select>
                  </ValidationProvider>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    :loading="new_participant_loading"
                    type="submit"
                    color="primary"
                    >Salvar</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-form>
          </ValidationObserver>
        </v-dialog>

        <file-dialog
          v-model="show_file_dialog"
          :attachment="file_to_show"
          @updateAttachment="updateVisitReportAttachment"
          @updateFile="updateFile"
          :no-delete="true"
        />

        <file-dialog
          v-model="show_image"
          :attachment="opened_image"
          @updateAttachment="updateVisitReportAttachment"
          @updateFile="updateImageFile"
          :no-delete="true"
        />
      </v-main>
    </div>
  </v-app>
</template>

<script>
import { updateAttachment, deleteAttachment } from "@/services/visit_reports";
import { index as indexRoomTypes } from "@/services/room_types";
import { formatDateTime, formatDate } from "@/tools/date";
import Money from "@/tools/money";
import MoneyInput from "@/components/MoneyInput";
import FileDialog from "@/components/FileDialog.vue";
import Timestamp from "@/components/tools/Timestamp";
import ParticipantsTypePicker from "@/components/participants/ParticipantsTypePicker";
import NamesTool from "@/tools/names";

export default {
  components: { MoneyInput, FileDialog, Timestamp, ParticipantsTypePicker },
  data() {
    const abreviate = NamesTool.abreviate;

    return {
      page: 1,
      drawer: null,
      state: "loading", //loading,not_found,finished,error,active
      snackbar: false,
      not_found: true,
      finished: false,
      form: {},
      images: [],
      attachments: [],
      header: {},
      task: {},
      new_participant: {},
      new_participant_loading: false,
      photo_dialog: false,
      room_types: [],
      form_image: {},
      form_image_submitting: false,
      total_uploaded: 0,
      opened_image: {},
      show_image: false,
      fails: [],
      loading_finish: false,
      coverages_dialog: false,
      policy_coverages_filter: "",
      research_date: "",
      research_time: "",
      participant_dialog: false,
      attachment_dialog: false,
      attachment_form: {},
      attachment_loading: false,
      show_file_dialog: false,
      file_to_show: {},
      submitting_form: false,
      abreviate,
    };
  },
  methods: {
    updateImageFile(file_changed) {
      this.images = this.images.map((file_for_change) => {
        return file_for_change.id === file_changed.id
          ? { ...file_changed }
          : file_for_change;
      });
    },
    updateFile(file_changed) {
      this.attachments = this.attachments.map((file_for_change) => {
        return file_for_change.id === file_changed.id
          ? { ...file_changed }
          : file_for_change;
      });
    },
    updateVisitReportAttachment(attachment, payload) {
      return updateAttachment(this.id, attachment, payload);
    },
    async handleOpenNewFileDialog() {
      this.attachment_form = {};
      if (this.$refs["attachment_form"]) {
        this.$refs["attachment_form"].reset();
      }
      this.attachment_dialog = true;
    },
    microsoft_preview(public_url) {
      return `https://view.officeapps.live.com/op/embed.aspx?src=${public_url}`;
    },
    getFilePublicUrl() {
      const file = this.file_to_show;
      switch (file.extension) {
        case "xlsx":
        case "pptx":
        case "docx":
          return this.microsoft_preview(file.public_url);
        default:
          return file.public_url;
      }
    },
    isFileImage() {
      const file = this.file_to_show;
      const image_extensions = ["jpg", "jpeg", "png", "gif"];

      let is_image = false;

      image_extensions.forEach((extension) => {
        if (extension === file.extension) {
          is_image = true;
        }
      });

      return is_image;
    },
    showFile(file) {
      this.file_to_show = { ...file };
      this.show_file_dialog = true;
      this.attachment_form = { ...file };
    },
    async handleUpdateAttachment() {
      const validation = await this.$refs["edit_attachment_form"].validate();
      if (!validation) return;

      this.attachment_loading = true;
      try {
        const { name, description } = this.attachment_form;

        const response = await this.$http.put(
          `/visit_reports/${this.id}/attachments/${this.attachment_form.id}`,
          {
            name,
            description,
          }
        );
        // this.attachments.push(response.data);
        this.attachments = this.attachments.map((attachment) => {
          if (attachment.id === this.attachment_form.id) {
            return {
              ...attachment,
              name,
              description,
            };
          } else {
            return attachment;
          }
        });

        this.attachment_form = {};
        this.$refs["edit_attachment_form"].reset();
        this.show_file_dialog = false;
      } catch (error) {
        alert("Não foi possivel atualizar o anexo.");
      } finally {
        this.attachment_loading = false;
      }
    },
    async handleSubmitAttachment() {
      const validation = await this.$refs["attachment_form"].validate();
      if (!validation) return;

      const file = this.attachment_form.file;
      this.attachment_loading = true;

      try {
        const formData = new FormData();
        formData.append("attachment", file);
        formData.append("description", this.attachment_form.description);
        formData.append("name", this.attachment_form.name);

        const response = await this.$http.post(
          `/visit_reports/${this.id}/attachments`,
          formData
        );
        this.attachments.push(response.data);

        this.attachment_loading = false;
        this.attachment_form = {};
        this.$refs["attachment_form"].reset();
        this.attachment_dialog = false;
      } catch (error) {
        alert("Não foi possivel enviar o anexo.");
      } finally {
        this.attachment_loading = false;
      }
    },
    setAttachment(file) {
      const name_parts = file.name.split(".");

      this.attachment_form.name = name_parts
        .slice(0, name_parts.length - 1)
        .join(".");
    },
    getFilesIcon(file) {
      let extension = file.extension;
      if (extension) {
        extension = extension.replace(".", "");
      }
      switch (extension) {
        case "jpg":
        case "jpeg":
        case "png":
        case "svg":
          return "mdi-image";
        case "pdf":
          return "mdi-file-pdf";
        case "doc":
        case "docm":
        case "dot":
        case "dotm":
        case "docx":
        case "dotx":
          return "mdi-file-word";
        case "pptx":
          return "mdi-file-powerpoint";
        case "xlsx":
          return "mdi-file-excel";
        case "zip":
        case "rar":
          return "mdi-zip-box";
        case "txt":
          return "mdi-file-document";
        default:
          return "mdi-file";
      }
    },
    getFilesIconColor(file_icon) {
      switch (file_icon) {
        case "mdi-file-pdf":
          return "#e5625b";
        case "mdi-file-word":
          return "#285495";
        case "mdi-file-excel":
          return "#1F7045";
        case "mdi-file-powerpoint":
          return "#CA4324";
        case "mdi-zip-box":
          return "#6186B2";
        case "mdi-image":
          return "#673AB7";
        default:
          return "";
      }
    },
    getParticipantDesire(desire) {
      if (desire == "rep") return "Reparação";
      else if (desire == "sub") return "Substituição";
      else if (desire == "ind") return "Indemnização";
      else return null;
    },
    handleNewParticipant() {
      this.participant_dialog = true;
      this.new_participant = {};

      const form = this.$refs["participant_form"];

      if (form) {
        form.reset();
      }
    },
    handleUpdateParticipant(participant) {
      this.participant_dialog = true;
      this.new_participant = { ...participant };
    },
    updateResearchDate() {
      if (this.research_time.length > 0) {
        this.updateAttribute(
          "research_date",
          `${this.research_date} ${this.research_time}`
        );
      }
    },
    updateResearchTime() {
      if (this.research_date.length > 0) {
        this.updateAttribute(
          "research_date",
          `${this.research_date} ${this.research_time}`
        );
      }
    },
    getResearchFullTime() {
      return `${this.research_date} ${this.research_time}`;
    },
    cleanNumber: function (value) {
      let result = "";
      if (value) {
        let flag = false;
        let arrayValue = value.toString().split("");
        for (var i = 0; i < arrayValue.length; i++) {
          if (this.isInteger(arrayValue[i])) {
            if (!flag) {
              // Retirar zeros à esquerda
              if (arrayValue[i] !== "0") {
                result = result + arrayValue[i];
                flag = true;
              }
            } else {
              result = result + arrayValue[i];
            }
          }
        }
      }
      return result;
    },
    isInteger(value) {
      let result = false;
      if (Number.isInteger(parseInt(value))) {
        result = true;
      }
      return result;
    },
    machineFormat(number) {
      if (number) {
        number = this.cleanNumber(number);
        // Ajustar quantidade de zeros à esquerda
        number = number.padStart(2 + 1, "0");
        // Incluir ponto na casa correta, conforme a precisão configurada
        number =
          number.substring(0, number.length - 2) +
          "." +
          number.substring(number.length - 2, number.length);
        if (isNaN(number)) {
          number = null;
        }
      } else {
        number = null;
      }
      if (2 === 0) {
        number = this.cleanNumber(number);
      }
      return number;
    },
    formatMoney(money) {
      return Money.euro(money);
    },
    formatDate(date) {
      return formatDate(date);
    },
    formatDateTime(date) {
      return formatDateTime(date);
    },
    async handleFinish() {
      this.loading_finish = true;
      try {
        const response = await this.$http.post(
          `/visit_reports/${this.id}/finish`
        );
        this.state = "finished";
      } catch (error) {
        if (error.response) {
          switch (error.response.status) {
            case 400:
              this.fails = error.response.data.fails;
              this.page = 2;
              await this.$vuetify.goTo(0);
              break;
            default:
              alert("Não foi possível finalizar a tarefa");
              break;
          }
        } else {
          alert("Não foi possível finalizar a tarefa");
        }
      } finally {
        this.loading_finish = false;
      }
    },
    showImage(image) {
      this.opened_image = image;
      this.show_image = true;
    },
    async uploadFiles() {
      const files = this.form_image.photos;
      const file_percent_value = 100 / files.length;
      this.form_image_submitting = true;

      for (let file of files) {
        const formData = new FormData();
        formData.append("photo", file);
        formData.append("description", this.form_image.description);

        const response = await this.$http.post(
          `/visit_reports/${this.id}/photos`,
          formData
        );
        this.total_uploaded += file_percent_value;
        this.images.push(response.data);
      }

      this.form_image_submitting = false;
      this.total_uploaded = 0;
      this.form_image = {};
    },
    async getRoomTypes() {
      const response = await indexRoomTypes();
      this.room_types = response;
    },
    async addParticipant() {
      const validation = await this.$refs["participant_form"].validate();
      if (!validation) return;

      this.new_participant_loading = true;
      try {
        if (this.new_participant.id) {
          const response = await this.$http.put(
            `/visit_reports/${this.id}/participant/${this.new_participant.id}`,
            this.new_participant
          );
          this.form.participants = this.form.participants.map((participant) => {
            if (participant.id === this.new_participant.id) {
              return response.data;
            } else {
              return participant;
            }
          });
        } else {
          const response = await this.$http.post(
            `/visit_reports/${this.id}/participant`,
            this.new_participant
          );
          this.form.participants.push(response.data);
        }

        this.new_participant = {};
        this.$refs["participant_form"].reset();
        this.participant_dialog = false;
      } catch (error) {
        alert(error);
      } finally {
        this.new_participant_loading = false;
      }
    },
    getAlertType() {
      const dead_line = this.getDeadLineInHours();

      if (dead_line > 24) {
        return "info";
      } else if (dead_line > 1) {
        return "warning";
      } else {
        return "error";
      }
    },
    getDeadline() {
      const limit_date = this.$moment(this.task.limit_date);

      return this.$moment().to(limit_date, true);
    },
    getDeadLineInHours() {
      const limit_date = this.$moment(this.task.limit_date);

      return limit_date.diff(this.$moment(), "hours");
    },
    getDeadLineInMinutes() {
      const limit_date = this.$moment(this.task.limit_date);

      return limit_date.diff(this.$moment(), "minutes");
    },
    async getTask() {
      this.state = "loading";
      try {
        const response = await this.$http.get(`/visit_reports/${this.id}`);
        this.state =
          response.data.task.finished === true ? "finished" : "active";
        this.form = response.data.form;
        this.header = response.data.header;
        this.task = response.data.task;
        this.images = response.data.images;
        this.attachments = response.data.attachments;

        if (this.form.research_date) {
          const date_parts = this.form.research_date.split(" ");

          this.research_date = date_parts[0];
          this.research_time = date_parts[1];
        }
        this.getRoomTypes();
      } catch (error) {
        if (error.response) {
          switch (error.response.status) {
            case 404:
              this.state = "not_found";
              break;
            default:
              this.state = "error";
              break;
          }
        } else {
          this.state = "error";
        }
      }
    },
    async updateAttribute(attribute, value) {
      const form_value = value === undefined ? null : value;

      const response = await this.$http.put(`/visit_reports/${this.id}`, {
        [attribute]: form_value,
      });

      this.form[attribute] = form_value;

      this.snackbar = true;
    },
    async updateAllForm() {
      this.submitting_form = true;
      try {
        await this.$http.put(`/visit_reports/${this.id}`, { ...this.form });
      } catch (error) {
        alert("Falha ao atualizar o formulário");
      } finally {
        this.submitting_form = false;
      }
    },
  },
  computed: {
    id: function () {
      return this.$route.params["id"];
    },
    filtered_policy_coverages: function () {
      if (!this.header.policy_coverages) return [];
      return this.header.policy_coverages.filter((policy_coverage) => {
        return policy_coverage.type_description
          .toLowerCase()
          .normalize("NFC")
          .includes(
            this.policy_coverages_filter.toLowerCase().normalize("NFC")
          );
      });
    },
  },
  watch: {
    page: function (page, old_page) {
      if (page == null) {
        this.page = old_page;
      } else if (this.$vuetify.breakpoint.mdAndDown) {
        this.drawer = false;
      }
    },
    research_date: function (value) {
      if (value && this.research_time.length > 0) {
        this.form.research_date = `${this.research_date} ${this.research_time}`;
      } else {
        this.form.research_date = null;
      }
    },
    research_time: function (value) {
      if (value && this.research_date.length > 0) {
        this.form.research_date = `${this.research_date} ${this.research_time}`;
      } else {
        this.form.research_date = null;
      }
    },
  },
  created() {
    this.getTask();
  },
};
</script>
